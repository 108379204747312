export class AppConstants {
    public static API_BASE_URL = "/api/";
    private static OAUTH2_URL = AppConstants.API_BASE_URL + "oauth2/authorization/";
    public static GOOGLE_AUTH_URL = AppConstants.OAUTH2_URL + "google";
}

export const CKEDITOR_CONFIG = {
    toolbarGroups: [
        { name: 'document', groups: ['mode', 'document', 'doctools'] },
        { name: 'clipboard', groups: ['clipboard', 'undo'] },
        { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
        { name: 'forms', groups: ['forms'] },
        { name: 'styles', groups: ['styles'] },
        { name: 'colors', groups: ['colors'] },
        { name: 'tools', groups: ['tools'] },
        { name: 'others', groups: ['others'] },
        '/',
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
        { name: 'links', groups: ['links'] },
        { name: 'insert', groups: ['insert'] }
    ],
    removeButtons: 'Maximize,About,Anchor,Scayt',
    allowExtraContent: true,
    extraAllowedContent: 'thing-document-link thing-document-link[name,target,page];style;div;*(*);*[*];*{*}',
    versionCheck: false
}

export const CKEDITOR_MINIMAL_CONFIG = {
    toolbarGroups: [
        { name: 'styles', groups: ['styles'] },
        { name: 'basicstyles', groups: ['basicstyles'] },
        { name: 'paragraph', groups: ['list', 'blocks'] },
        { name: 'links', groups: ['links'] }
    ],
    removeButtons: 'Maximize,About,Anchor,Scayt,Subscript,Superscript,Styles,Unlink,Mention,Blockquote',
    allowExtraContent: true,
    versionCheck: false,
    height: 400
}

export const LIBS_PATH = {
    CKEDITOR: "https://cdn.ckeditor.com/4.22.1/standard/ckeditor.js"
}

export const UNSAVED_CHANGES_KEY = 'sy-console-unsaved-changes';